<template lang="pug">
div
  Breadcrumbs(:breadcrumbs="breadcrumbs")

  TableV2(
    :actions="actions"
    :headers="headers"
    :isLoading="isLoading"
    :items="items"
    :querySearch="getData"
    :saveReport="getCrewingManagersReportExcel"
  ).px-4
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'TableManager',
  components: {
    Breadcrumbs: () => import('@/components/atoms/Breadcrumbs')
  },
  data () {
    return {
      headers: [
        { value: 'full_name', text: this.$t('crewingManager'), sortable: false },
        { value: 'count_connect', text: this.$t('itemsAmount'), sortable: false },
        { value: 'event', text: this.$t('actions'), align: 'center', sortable: false }
      ],
      actions: [
        {
          id: 1,
          to: (item) => ({
            name: 'crewing-connect-sailor-report',
            params: { ...this.$route.params, managerID: item.manager_id },
            query: { ...this.$route.query, manager: item.manager_id } }),
          color: 'blue',
          target: '_blank',
          name: 'mdi-arrow-right',
          tooltip: 'tooltip.go',
          buttonOptions: { xSmall: false, icon: true }
        }
      ],
      breadcrumbs: {
        historyBreadcrumbs: [
          {
            text: this.$t('companies'),
            link: { name: 'crewing-connect-company-report', params: { ...this.$route.params }, query: { ...this.$route.query } },
            disabled: this.$route.name === 'crewing-connect-company-report'
          },
          {
            text: this.$t('managers'),
            link: { name: 'crewing-connect-manager-report', params: { ...this.$route.params }, query: { ...this.$route.query } },
            disabled: this.$route.name === 'crewing-connect-manager-report'
          }
        ],
        checkAccess: () => true
      }
    }
  },
  computed: {
    ...mapState({
      items: state => state.report.listCrewingManagers,
      isLoading: state => state.report.isLoadingReport
    })
  },
  methods: {
    ...mapActions(['getListCrewingManagers', 'getCrewingManagersReportExcel']),
    async getData (params) {
      this.getListCrewingManagers({ params })
    }

  }
}
</script>
